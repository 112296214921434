import { ReactComponent as Logo } from "../../assets/images/icons/logo.svg";

import GooglePlayIconEn from "../../assets/images/social/google-play-icon-en.svg";
import GooglePlayIconDe from "../../assets/images/social/google-play-icon-de.svg";
import GooglePlayIconEs from "../../assets/images/social/google-play-icon-es.svg";

import AppStoreIconEn from "../../assets/images/social/app-store-icon-en.svg";
import AppStoreIconDe from "../../assets/images/social/app-store-icon-de.svg";
import AppStoreIconEs from "../../assets/images/social/app-store-icon-es.svg";

import InstagramIcon from "../../assets/images/social/instagram-icon.svg";
import TikTokIcon from "../../assets/images/social/tik-tok-icon.svg";
import LinkedInIcon from "../../assets/images/social/linkedin-icon.svg";
import TwitterIcon from "../../assets/images/social/twitter-icon.svg";

import PrivacyPolicyDocument from '../../assets/documents/privacy-policy.pdf';

import { useTranslation } from "react-i18next";

import "./styled.css";

function Footer({ scrollToAbout, scrollToWelcome, scrollToInformation, scrollToProduct}) {
  const {t, i18n} = useTranslation()

  return (
    <footer className='footer'>

      <div className="topContainer">

        <div className="leftContainer">
          <Logo/>

          <div className="comingSoonContainer">
            <p>{t('footer.comingSoon')}</p>

            <div className="comingSoonImg">

              <div className="img">
                <img src={i18n.language === 'en' ? AppStoreIconEn : i18n.language === 'de' ? AppStoreIconDe : AppStoreIconEs} alt="AppStore"></img>
              </div>

              <div className="img">
                <img src={i18n.language === 'en' ? GooglePlayIconEn : i18n.language === 'de' ? GooglePlayIconDe : GooglePlayIconEs} alt="GooglePlay"></img>
              </div>
            </div>
          </div>
        </div>

        <div className="rightContainer">

          <div className="navigationContainer">
            <div className="navigationContainer">
              <h3>{t('footer.navigation')}</h3>
              <p onClick={scrollToWelcome}>{t('footer.home')}</p>
              <p onClick={scrollToAbout}>{t('footer.aboutUs')}</p>
              <p onClick={scrollToProduct}>{t('footer.product')}</p>
              <p onClick={scrollToInformation}>{t('footer.information')}</p>
            </div>

            <div className="navigationContainer">
              <h3>{t('footer.legal')}</h3>
              <p>{t('footer.service')}</p>
              <p><a href={PrivacyPolicyDocument} target="_blank" rel="noopener noreferrer">{t('footer.privacy')}</a></p>
            </div>
          </div>

          <div className="navigationContainer">
            <div className="contactContainer">
              <h3>{t('footer.contactUs')}</h3>
              <p><a href="mailto:support@drankly.com">support@drankly.com</a></p>
              <p><a href="tel:+48 573 376 556">+48 572 390 290</a></p>
            </div>

            <div className="socialNetworkContainer">
              <h3>Follow us</h3>
              <p><a href="https://www.instagram.com/drankly.app/" target="_blank" rel="noreferrer">Instagram</a></p>
              <p><a href="https://www.tiktok.com/@drankly" target="_blank" rel="noreferrer">TikTok</a></p>
              <p><a href="https://www.linkedin.com/company/drankly" target="_blank" rel="noreferrer">Linkedln</a></p>
              <p><a href="https://x.com" target="_blank" rel="noreferrer">Twitter</a></p>
            </div>
          </div>
        </div>
      </div>

      <div className="bottomContainer">

        <div className="rightsReserved">
          <p>© 2024 {t('footer.allRightsReserved')} </p>
        </div>

        <div className="iconsContainer">

          <div className="contactIcon">
            <a href="https://www.instagram.com/drankly.app/" target="_blank" rel="noreferrer">
              <img src={InstagramIcon} alt='Instagram'></img>
            </a>
          </div>

          <div className="contactIcon">
            <a href="https://www.tiktok.com/@drankly" target="_blank" rel="noreferrer">
              <img src={TikTokIcon} alt='TikTok'></img>
            </a>
          </div>

          <div className="contactIcon">
            <a href="https://www.linkedin.com/company/drankly" target="_blank" rel="noreferrer">
              <img src={LinkedInIcon} alt='LinkedIn'></img>
            </a>
          </div>

          <div className="contactIcon">
            <a href="https://x.com" target="_blank" rel="noreferrer">
              <img src={TwitterIcon} alt='Twiter'></img>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
