import cocktailRecipeImg from '../../assets/images/features/cocktail-recipe-img.jpg'
import aiMixologistImg from '../../assets/images/features/ai-mixologist-img.jpg'
import partyPlannerImg from '../../assets/images/features/party-planner-img.jpg'
import smartBarImg from '../../assets/images/features/smart-bar-img.jpg'
import virtualMixologistImg from '../../assets/images/features/virtual-mixologist-img.jpg'
import cocktailsCommunityImg from '../../assets/images/features/cocktails-community-img.jpg'

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SuggestionsIcon from "../../assets/images/icons/suggestions-icon.svg";
import CocktailGlassesIcon from "../../assets/images/icons/cocktail-glasses-icon.svg";
import CocktailAnalogsIcon from "../../assets/images/icons/cocktail-analogs-icon.svg";
import RecommendationsIcon from "../../assets/images/icons/recommendations-icon.svg";

import { useTranslation } from "react-i18next";

import "./styled.css";

function CustomSlide({title, subtitle, description, imgSrc, tags}) {
  return (
    <div className="cocktailCard">
      <div className="cardText">
        <p>{subtitle}</p>
        <h3>{title}</h3>
        <div className='cardTags'>
          {tags.map((text) => <p key={text}>{text}</p>)}
        </div>
        <div className="cardDescription">
          <p>{description}</p>
        </div>
      </div>
      <div className="gradient"></div>
      <img src={imgSrc} alt="smartBarImg" />
    </div>
  );
}

function CocktailsCards() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows: false,
    className: "centerCocktails",
    customPaging: i => (
      <div className='dots'></div>
    ),
  };

  const {t} = useTranslation()

  return (
    <div className="cocktailsWrapper">
      <div className="cocktailsCards">
        <Slider {...settings}>

          <CustomSlide title={t('product.slide1.title')} subtitle={t('product.slide1.subtitle')} description={t('product.slide1.description')} imgSrc={cocktailRecipeImg} tags={[t('product.slide1.tags.1'), t('product.slide1.tags.2'), t('product.slide1.tags.3')]}/>

          <CustomSlide title={t('product.slide2.title')} subtitle={t('product.slide2.subtitle')} description={t('product.slide2.description')} imgSrc={aiMixologistImg} tags={[t('product.slide2.tags.1')]}/>

          <CustomSlide title={t('product.slide3.title')} subtitle={t('product.slide3.subtitle')} description={t('product.slide3.description')} imgSrc={partyPlannerImg} tags={[t('product.slide3.tags.1'), t('product.slide3.tags.2')]}/>

          <CustomSlide title={t('product.slide4.title')} subtitle={t('product.slide4.subtitle')} description={t('product.slide4.description')} imgSrc={smartBarImg} tags={[t('product.slide4.tags.1'), t('product.slide4.tags.2')]}/>

          <CustomSlide title={t('product.slide5.title')} subtitle={t('product.slide5.subtitle')} description={t('product.slide5.description')} imgSrc={virtualMixologistImg} tags={[t('product.slide5.tags.1')]}/>

          <CustomSlide title={t('product.slide6.title')} subtitle={t('product.slide6.subtitle')} description={t('product.slide6.description')} imgSrc={cocktailsCommunityImg} tags={[t('product.slide6.tags.1'), t('product.slide6.tags.2')]}/>

        </Slider>
      </div>
      <div className='iconContainer'>
        <div className='icon'>
          <div className='icoImg'>
            <img src={CocktailGlassesIcon} alt='More than 1000 cocktails'></img>
          </div>
          <p>{t('information.cocktails')}</p>
        </div>
        <div className='icon'>
          <div className='icoImg'>
            <img src={RecommendationsIcon} alt='Advice'></img>
          </div>
          <p>{t('information.recommendations')}</p>
        </div>
        <div className='icon'>
          <div className='icoImg'>
            <img src={SuggestionsIcon} alt='Advice'></img>
          </div>
          <p>{t('information.suggestions')}</p>
        </div>
        <div className='icon'>
          <div className='icoImg'>
            <img src={CocktailAnalogsIcon} alt='Advice'></img>
          </div>
          <p>{t('information.analogues')}</p>
        </div>
      </div>
    </div>
  );
}

export default CocktailsCards;
