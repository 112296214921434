import { useEffect, useRef } from "react";
import Header from "./components/Header";
import WelcomeScreen from "./components/WelcomeScreen";
import AboutScreen from "./components/AboutScreen";
import CocktailsCards from "./components/CocktailsScreen";
import Footer from "./components/Footer";

import "./app.css";

function App() {
  const aboutRef = useRef(null);
  const informationRef = useRef(null);
  const productRef = useRef(null);

  useEffect(() => {
    const handleScroll = (event) => {
      if (window.innerWidth >= 1440) {
        if (window.pageYOffset <=  window.innerHeight*2-100) {
          document.body.style.overflow = 'hidden';
          if (
            event.deltaY > 0 &&
            window.innerWidth >= 1440
            // && window.innerHeight >= 900
          ) {
            const screenHeight = window.innerHeight;
            window.scrollTo({
              top: window.pageYOffset + screenHeight,
              behavior: "smooth",
            });
          } else if (
            event.deltaY < 0 &&
            window.innerWidth >= 1440
            
            // && window.innerHeight >= 900
          ) {
            const screenHeight = window.innerHeight;
            window.scrollTo({
              top: window.pageYOffset - screenHeight,
              behavior: "smooth",
            });
          } else {
            window.scrollTo({
              top: window.pageYOffset + event.deltaY,
              behavior: "auto",
            });
          }
        }
        else {
          document.body.style.overflow = 'visible';
        }
      }
      
      
    };

    // const scrollEvent = "onwheel" in document ? "wheel" : "touchmove";

    if (window.innerWidth >= 1440) {
      window.addEventListener("wheel", handleScroll);
    }

    return () => {
      window.removeEventListener("wheel", handleScroll);
    };
  }, []);

  const scrollToAbout = () => {
    aboutRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToProduct = () => {
    productRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToInformation = () => {
    informationRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToWelcome = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="appWrapper" id="wrapper">
      <Header
        scrollToAbout={scrollToAbout}
        scrollToProduct={scrollToProduct}
        scrollToWelcome={scrollToWelcome}
        scrollToInformation = {scrollToInformation}
      />
      <div className="container">
        <WelcomeScreen />
        <div className="colorContainer" ref={aboutRef}>
          <AboutScreen/>
        </div>
        <div ref={productRef}>
          <CocktailsCards />
        </div>
      </div>
      <div ref={informationRef}>
        <Footer 
          scrollToAbout={scrollToAbout}
          scrollToProduct={scrollToProduct}
          scrollToWelcome={scrollToWelcome}
          scrollToInformation = {scrollToInformation}
        />
      </div>
    </div>
  );
}

export default App;
