import { animated, useInView } from "@react-spring/web";
import webMockupCocktails from "../../assets/images/mockups/web-mockup-cocktails.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import campariClassicCocktails from "../../assets/images/cocktails/campari-classic.jpg";
import martiniCocktail from "../../assets/images/cocktails/martini.jpg";
import redCranberryCocktail from "../../assets/images/cocktails/cranberry-cocktail-1.jpg";
import alabamaCocktail from "../../assets/images/cocktails/alabama.jpg";
import aperolCocktail from "../../assets/images/cocktails/aperol.jpg";
import cranberryCocktail from "../../assets/images/cocktails/cranberry-cockail-2.jpg";
import blueLagoonCocktail from "../../assets/images/cocktails/blue-lagoon.jpg";
import { useTranslation } from "react-i18next";

import { useEffect } from "react";


import "./styled.css";

function CustomSlide({src}) {
  return (
    <div className="aboutSlide">
      <img src={src} alt="cockailList" />
    </div>
  );
}

function AboutScreen() {
  const [content, contentView] = useInView(
    () => ({
      from: {
        opacity: window.innerWidth >= 1440 ? 0 : 1,
      },
      to: {
        opacity: 1,
      },
      config: {
        duration: 500,
      },
    }),
    {
      rootMargin: "-40% 0%",
    }
  );

  const settings = {
    centerPadding: '45px',
    swipe: false,
    infinite: true,
    speed: 500,
    arrows: false,
    className: "centerAbout",
    autoplay: true,
    autoplaySpeed: 5000,
    beforeChange: (current, next) => {
      updateVisibility(next);
    }
  };

  const {t} = useTranslation()


    const updateVisibility = (nextIndex) => {
      const allSlides = document.querySelectorAll('.centerAbout .slick-slide');
      allSlides.forEach((slide, index) => {
        if (
          index === nextIndex ||
          index === (nextIndex + 1) % 15 ||
          index === (nextIndex + 2) % 15
        ) {
          slide.style.visibility = 'visible';
        }else {
          slide.style.visibility = 'hidden';
        }
      });
    };

    useEffect(() => {
      updateVisibility(0);
    }, []);


  return (
    <div className="aboutWrapper">
      <div className="aboutScreen">
        <animated.div
          className="aboutContent"
          ref={content}
          style={contentView}
        >
          <Slider {...settings}>
            <CustomSlide src={redCranberryCocktail}/>
            <CustomSlide src={martiniCocktail}/>
            <CustomSlide src={campariClassicCocktails}/>
            <CustomSlide src={alabamaCocktail}/>
            <CustomSlide src={blueLagoonCocktail}/>
            <CustomSlide src={aperolCocktail}/>
            <CustomSlide src={cranberryCocktail}/>
          </Slider>
          <div className="aboutText">
            <h2>{t('aboutUs.title')}</h2>
            <p>
              {t('aboutUs.subDescription')}
            </p>
            <p>
              {t('aboutUs.description.start')}
              <del>{t('aboutUs.description.delete')}</del>
              {t('aboutUs.description.end')}
            </p>
          </div>
          <div className="aboutScreenImage">
            <img src={webMockupCocktails} alt="webCocktails" />
          </div>
        </animated.div>

      </div>
    </div>
  );
}

export default AboutScreen;
